import axios from 'axios';
import common from './common.js';

let nanoid=(t=21)=>crypto.getRandomValues(new Uint8Array(t)).reduce(((t,e)=>t+=(e&=63)<36?e.toString(36):e<62?(e-26).toString(36).toUpperCase():e>62?"-":"_"),"");
let uid = window.localStorage.getItem('uid') || nanoid(32);
window.localStorage.setItem('uid', uid);

axios.defaults.baseURL = common.apiBaseUrl;
axios.defaults.headers.common['Authorization'] = '77185155e6c84d6eba061dd994b6b532';
axios.defaults.headers.common['UID'] = uid;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true

const api = {
    api_then(res, callback) {
        let _data = res.data;
        if(_data.code === 0) {
            callback(_data.data, _data.page);
        }else {
            this.$vue.$message({
                type: 'error',
                message: '[' + _data.code + '] ' + _data.msg,
                showClose: true,
                duration: 0
            });
        }
    },
    api_catch(err) {
        let errMsg = err.response ? '(' + err.response.status + ')糟糕，服务器不见了！' : err.message;
        let errTitle = err.response ? '服务器异常' : '系统异常';
        this.$vue.$message({
            type: 'error',
            message: errTitle + '-' + errMsg,
            showClose: true,
            duration: 0
        });
    },
    get(url, params, callback) {
        axios.get(url, {params}).then((res) => {
            this.api_then(res, callback);
        }).catch((err) => {
            this.api_catch(err);
        });
    },
    post(url, data, callback) {
        axios.post(url, data).then((res) => {
            this.api_then(res, callback);
        }).catch((err) => {
            this.api_catch(err);
        });
    }
}

export default api
