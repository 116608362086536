import Vue from 'vue'
import VueRouter from 'vue-router'

// 注册路由插件
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('./views/Home.vue'),
        children: []
    },{
        path: '/list',
        name: 'List',
        component: () => import('./views/List.vue')
    },{
        path: '/works/:worksId',
        name: 'WorksInfo',
        component: () => import('./views/WorksInfo.vue')
    },{
        path: '/goods/:goodsId',
        name: 'GoodsInfo',
        component: () => import('./views/GoodsInfo.vue')
    },{
        path: '/download',
        name: 'DownloadApp',
        component: () => import('./views/Download.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router