
const util = {
    /**
     * 去除字符串首尾空格
     * @param str 字符串
     * @returns {*}
     */
    trim: str => {
        return str.replace(/^\s*/, '').replace(/\s*$/, '');
    },
    /**
     * 判断对象是否为函数类型
     * @param val 任意对象
     */
    isFunction: val => {
        return Object.prototype.toString.call(val) === '[object Function]';
    },

    /**
     * 判断对象是否为数组类型
     * @param val 任意对象
     */
    isArray: val => {
        return Object.prototype.toString.call(val) === '[object Array]';
    },

    isDate: val => {
        return Object.prototype.toString.call(val) === '[object Date]';
    },

    isObject: val => {
        return val !== null && typeof val === 'object';
    },

    isString: val => {
        return typeof val === 'string';
    },

    isNumber: val => {
        return typeof val === 'number';
    },
    isEmpty: val => {
        return val === null || typeof val === 'undefined' || val === '' || JSON.stringify(val) === '[]' || JSON.stringify(val) === '{}' || val.length === 0;
    },
    isNotEmpty: val => {
        return !util.isEmpty(val);
    },
    isNull: val => {
        return val === null && typeof val === 'undefined';
    },
    isUndefined: val => {
        return typeof val === 'undefined';
    },
    uuid: () => {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return 'crm' + S4() + S4() + new Date().getTime() + S4() + S4();
    },
    nvl: (val, defaultValue) => {
        return util.isEmpty(val) ? defaultValue : val;
    },
    isMobile: () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    /**
     * 判断浏览区是否支持canvas
     * @returns {boolean}
     */
    isSupportCanvas() {
        const elem = document.createElement('canvas');
        return !!(elem.getContext && elem.getContext('2d'));
    },
    sizeFormat: value => {
        if (value === +value) {
            let unit = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
            let index = Math.floor(Math.log(value) / Math.log(1024));
            let size = value / Math.pow(1024, index);
            size = size.toFixed(1);
            return size + unit[index];
        }
        return '';
    },
    numFormat: value => {
        if (value === +value) {
            value = util.nvl(value, 0);
            if(value >= 100000000) {
                return (value / 100000000).toFixed(0) + "万";
            }else if(value >= 10000) {
                return (value / 10000).toFixed(0) + "万";
            }else {
                return value;
            }
        }
        return 0;
    }
}

export default util