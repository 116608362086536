import Vue from 'vue'
import App from './App.vue'
import router from './router.js';
import api from '@/assets/js/api.js';
import util from '@/assets/js/util.js';
import common from '@/assets/js/common.js';
import {Button, Input, Breadcrumb, BreadcrumbItem, Popover, Carousel, CarouselItem, Tag, Message, Loading} from 'element-ui';
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';


Vue.prototype.api = api;
Vue.prototype.util = util;
Vue.prototype.common = common;
Vue.config.productionTip = false;

Vue.use(Button);
Vue.use(Input);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Popover);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Tag);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
// Vue.use(ElementUI);

new Vue({
    el: '#app',
    router,
    render: h => h(App),
    mounted () {
        this.api.$vue = this;
    }
})
